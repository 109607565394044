<template>
  <div
    class="bg-white dark:bg-slate-800 shadow rounded-md max-w-sm w-full mx-auto"
  >
    <div class="animate-pulse flex flex-col space-3-4 items-center">
      <div
        class="object-cover h-40 w-40 rounded-full bg-slate-700 p-2 md:p-4"
      />

      <div
        class="flex gap-3 flex self-start flex-col justify-between p-2 md:p-4 w-full"
      >
        <div class="h-5 bg-slate-700"></div>
        <div class="h-3 bg-slate-700"></div>
      </div>
    </div>
  </div>
</template>
